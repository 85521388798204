import OutsideHead from '../components/outside/OutsideHead';
import OutsideContent from '../components/outside/OutsideContent';

export default function OutsideApartment() {
  return (
    <>
      <OutsideHead />
      <OutsideContent />
    </>
  );
}