import PricesContent from '../components/prices/PricesContent';
import PricesHead from "../components/prices/PricesHead";

export default function Prices() {
  return (
    <>
      <PricesHead />
      <PricesContent />
    </>
  );
}