import StayHead from '../components/stay/StayHead';
import StayContent from '../components/stay/StayContent';

export default function Facilities() { 
  return (
    <>
      <StayHead />
      <StayContent />
    </>
  );
}