import FacilitiesHead from '../components/facilities/FacilitiesHead';
import FacilitiesContent from '../components/facilities/FacilitiesContent';

export default function Facilities() {
  return (
    <>
      <FacilitiesHead />
      <FacilitiesContent />
    </>
  );
}