import InsideHead from '../components/inside/InsideHead';
import InsideContent from '../components/inside/InsideContent';

export default function InsideApartment() {
  return (
    <>
      <InsideHead />
      <InsideContent />
    </>
  );
}